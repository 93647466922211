import React, { useState, FormEvent, ChangeEvent } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
// TODO
import { regex_email } from "../../utils/validation/registerValidate"

import loadingGif from "../../images/spinner.gif"
import { mediaMin, mediaMax } from "../../utils/breakpoints"

import contactForm, { FORM_NAME } from "../../utils/netlifyForms/contactForm"
import CloseButton from "../Header/components/CloseButton"

const startingInputs = {
  name: {
    name: "name",
    value: "",
    label: "Nafn",
  },
  email: {
    name: "email",
    value: "",
    label: "Netfang",
  },
  message: {
    name: "message",
    value: "",
    label: "Skilaboð",
  },
}

interface ContactFormProps {
  show?: boolean
  onClose?: () => void
  location: Location
}
export default ({ show, onClose, location }: ContactFormProps) => {
  const data = useStaticQuery(
    graphql`
      query {
        contactImage: file(relativePath: { eq: "Heilbrigdisthing-monnun_menntun-1200x1200.jpg" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(startingInputs)
  const [errors, setErrors] = useState<any>({})
  const [submitted, setSubmitted] = useState(false)

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name
    const value = event.target.value
    const input = inputs[name]

    setInputs({
      ...inputs,
      [name]: { ...input, value: value },
    })
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    // Validation
    let errors: any = {}
    if (!inputs.name.value) {
      errors["name"] = "*Vantar að fylla inn nafn"
    }
    if (!inputs.email.value) {
      errors["email"] = "*Vantar að fylla inn netfang"
    } else if (!regex_email.test(String(inputs.email.value).toLowerCase())) {
      errors["email"] = "*Ógilt netfang"
    }
    if (!inputs.message.value) {
      errors["message"] = "*Vantar að fylla inn skilaboð"
    }
    setErrors({ ...errors })

    // Check for errors
    if (Object.keys(errors).length !== 0) {
      return
    }

    setLoading(true)

    if (!loading) {
      contactForm({
        name: inputs.name.value,
        email: inputs.email.value,
        message: inputs.message.value,
        pathname: location.pathname,
      })
        .then(res => {
          setTitle("Takk fyrir að hafa samband")
          setText("Við svörum þér við fyrsta tækifæri.")
          setSubmitted(true)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const [title, setTitle] = useState("Góðan dag,")
  const [text, setText] = useState(
    "Vantar þig aðstoð varðandi skráningu á þingið? Hafðu samband og við svörum við fyrsta tækifæri."
  )

  return (
    <StyledBackground className={show ? "active" : ""}>
      <ClosingDiv onClick={onClose} />

      <StyledModal>
        <StyledCloseButton onClick={onClose}></StyledCloseButton>
        <SideImage fluid={data.contactImage.childImageSharp.fluid} />

        <ModalContentWrapper>
          <ModalContent>
            <h2>{title}</h2>
            <p>{text}</p>

            <ModalForm className={submitted ? "submitted" : ""}>
              <form name={FORM_NAME} onSubmit={handleSubmit} noValidate>
                <input type="hidden" name="form-name" value={FORM_NAME} />

                <input
                  type="hidden"
                  name="website-page"
                  value={location.pathname}
                />

                <label htmlFor="name" className="sr-only">
                  {inputs.name.label}
                </label>

                <input
                  type="text"
                  name={inputs.name.name}
                  id={inputs.name.name}
                  placeholder={inputs.name.label}
                  onChange={handleChange}
                  className={errors.name ? `error` : ``}
                ></input>
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}

                <label htmlFor="email" className="sr-only">
                  {inputs.email.label}
                </label>
                <input
                  type="email"
                  name={inputs.email.name}
                  id={inputs.email.name}
                  placeholder={inputs.email.label}
                  onChange={handleChange}
                  className={errors.email ? `error` : ``}
                ></input>
                {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}

                <label htmlFor="message" className="sr-only">
                  {inputs.message.label}
                </label>
                <textarea
                  name={inputs.message.name}
                  id={inputs.message.name}
                  placeholder={inputs.message.label}
                  onChange={handleChange}
                  className={errors.message ? `error` : ``}
                ></textarea>
                {errors.message && (
                  <ErrorMessage>{errors.message}</ErrorMessage>
                )}

                <StyledButton type="submit">
                  <StyledSpinner
                    className={loading ? "active" : ""}
                    src={loadingGif}
                    alt="spinner"
                    aria-hidden="true"
                  />
                  <ButtonText className={loading ? "hidden" : ""}>
                    Senda
                  </ButtonText>
                </StyledButton>
              </form>
            </ModalForm>
          </ModalContent>
        </ModalContentWrapper>
      </StyledModal>
    </StyledBackground>
  )
}

const ClosingDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`

const ErrorMessage = styled.div`
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  color: #606060;
`

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: -25px;
  right: -20px;
`

const StyledModal = styled.div`
  width: 67vw;
  position: absolute;
  top: 15vh;
  height: 75vh;
  bottom: 10vh;
  background-color: white;
  display: flex;
  z-index: 30;

  @media ${mediaMax.tablet} {
    width: 90vw;
    height: 95%;
    top: 2vh;
  }
`

const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
  opacity: 0;
  z-index: 0;
  transition: z-index 0.5s step-end, opacity 0.5s cubic-bezier(0.8, 0, 0.07, 1);
  background: linear-gradient(135deg, rgba(0,130,133,0) 0%, rgba(0,130,133,0.7) 23%, rgba(0,130,133,1) 100%);

  &.active {
    pointer-events: auto;
    transition: z-index 0.5s step-start,
      opacity 0.5s cubic-bezier(0.8, 0, 0.07, 1);
    z-index: 210;
    opacity: 1;
  }
`

const SideImage = styled(Img)`
  height: 100%;
  width: 26%;

  img{
    object-position: bottom right !important;
  }

  @media ${mediaMax.mobileL} {
    display: none;
  }
`

const ModalContentWrapper = styled.div`
  width: calc(100% - 200px);
  margin-top: 12%;
  height: 79%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mediaMax.tablet} {
    width: 90%;
  }

  @media ${mediaMax.mobileS} {
    margin-top: 0%;
  }
`

const ModalContent = styled.div`
  width: 67%;

  h2 {
    color: #303030;
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 38px;
    margin-bottom: 15px;
  }

  p {
    color: #606060;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
    max-width: 375px;
    margin-bottom: 35px;
  }
  @media ${mediaMax.tablet} {
    width: 80%;
    margin-top: 40px;
  }

  @media ${mediaMax.mobileS} {
    p {
      margin-bottom: 0;
    }
  }
`

const ModalForm = styled.div`
  &.submitted {
    pointer-events: none;
    opacity: 0;
  }

  input,
  textarea {
    width: 100%;
    max-width: 100%;
    height: 45px;
    border-radius: 22.5px;
    background-color: #ffffff;
    margin-bottom: 10px;
    border: 1px solid #e5e5e5;
    padding-left: 20px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    padding-top: 2px;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};

    &:focus {
      outline: none;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #808080;
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #808080;
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};      
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }

    ::placeholder {
      color: #808080;
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};      
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }

    &.error {
      border: 2px solid rgba(240, 52, 52, 1);
    }
  }

  textarea {
    height: 100px;
    max-height: 300px;
    padding-top: 10px;
  }
`

const StyledSpinner = styled.img`
  display: none;
  height: 40px;
  width: 40px;

  &.active {
    display: block;
  }
`

const ButtonText = styled.span`
  display: block;

  &.hidden {
    display: none;
  }
`

const StyledButton = styled.button`
  height: 45px;
  width: 100%;
  border-radius: 22.5px;
  background:  linear-gradient(90deg, rgba(0,130,133,1) 45%, rgba(77,207,210,1) 73%);
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }
`
