import firebase from 'firebase/app';
import 'firebase/auth'; // importing the auth module as an example
import 'firebase/database'; // importing the auth module as an example

const config = {
  apiKey: "AIzaSyA5Nn0w00FzP0OCq96_JxYsPfDeWbPsZyw",
  authDomain: "heilbrigdisradstefna.firebaseapp.com",
  databaseURL: "https://heilbrigdisradstefna.firebaseio.com",
  projectId: "heilbrigdisradstefna",
  storageBucket: "heilbrigdisradstefna.appspot.com",
  messagingSenderId: "935394545167",
  appId: "1:935394545167:web:256e84f41ca38045e89d7a"
}

let instance = null;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
}