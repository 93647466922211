import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import styled from 'styled-components';

import { mediaMax } from '../../utils/breakpoints'

export default () => {
	const [cookieConsent, setCookieConsent] = useState(true)

	useEffect(() => {
		if(Cookies.get("CookieConsent") === "true") {
			setCookieConsent(true)
		}
		else {
			setCookieConsent(false)
		}
	}, [])

	const handleAccept = () => {
		Cookies.set("CookieConsent", "true", { expires: 365 })
		setCookieConsent(true)
	}

	return (
		<CookieConsent className={!cookieConsent && "show"}>
			<div className="cookie-title">
				Þetta vefsvæði notast við vafrakökur
			</div>

			<div className="cookie-text">
				Það er til þess að tryggja eðlilega virkni og notendaupplifun. 
				Með því að halda áfram notkun síðunnar, samþykkir þú notkun vafrakaka.
			</div>

			<button onClick={handleAccept} type="button" className="cookie-button">Samþykkja</button>
		</CookieConsent>
	)
}

const CookieConsent = styled.div`
	position: fixed;
	right: 30px;
	bottom: 30px;
	z-index: 400;
	max-width: 340px;
	width: 100%;
  border-radius: 3px;
  background-color: #FFFFFF;
	box-shadow: 0 8px 30px 0 rgba(0,0,0,0.15);
	padding: 31px 20px 40px 20px;
	display: none;

	&.show {
		display: block;
	}

	@media ${mediaMax.tablet} {
		right: 50%;
    transform: translateX(50%);
	}

	.cookie-title {
		color: #202020;
		font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 32px;
		padding-bottom: 17px;
	}

	.cookie-text {
		color: #606060;
		font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};		
		font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
		padding-bottom: 24px;
	}

	.cookie-button {
		cursor: pointer;
		/* padding: 6.5px 0 10.5px 0; */
		padding-top: 3px;
    height: 45px;
		margin: 0;
		border: none;
		background: transparent;
		border-radius: 22.5px;
		background: linear-gradient(270deg, #F3DC30 0%, #CE5120 100%);
		width: 100%;
		color: #FFFFFF;
		font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 28px;

		&:focus {
			outline: none;
		}
	}
`