/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import favicon from '../images/skjaldam.png'
import haustradstefnaSocial from '../images/haustradstefnaSocial.png'

// haustradstefnaSocial.png

export interface MetaOG {
  type?: string,
  imageHeight?: string,
  imageWidth?: string,
  locale?: string,
  siteName?: string,
  title?: string,
  description?: string
  image?: string
}

interface SEOProps {
  title?: string,
  lang?: string,
  description?: string,
  og?: MetaOG,
}

export default ({
  description,
  lang,
  title,
  og = {}
}: SEOProps) => {
  const { pathname } = useLocation()

  // TODO: Reasonable preset values.
  const { site, shearImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            defaultSiteName: siteName
            siteUrl
            author
          }
        }
        shearImage: file(relativePath: { eq: "haustradstefnaSocial.png" }) {
					childImageSharp {
						fixed( width: 1200, height: 628, quality: 70 ) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
      }
    `
  )

  const {
    defaultTitle,
    defaultDescription,
    author,
    defaultSiteName,
    siteUrl
  } = site.siteMetadata

  const seo = {
    title: title ? title : defaultTitle,
    description: description ? description : defaultDescription,
    //image: `${siteUrl}${shearImage.childImageSharp.fixed.src}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    // TODO
    <Helmet
      title={title}
      titleTemplate={`%s | ${defaultTitle}`}

      htmlAttributes={{
        lang: `is`,
      }}

      meta={[
        {
          name: `description`,
          content: description ? description : defaultDescription,
        },
        {
          property: `og:title`,
          content: og.title ? og.title : defaultTitle,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:description`,
          content: og.description ? og.description : defaultDescription,
        },
        {
          property: `og:type`,
          content: og.type ? og.type : `website`,
        },
        {
          property: `og:locale`,
          content: og.locale ? og.locale : `is_IS`,
        },
        {
          property: `og:site_name`,
          content: og.siteName ? og.siteName : defaultSiteName,
        },

        // {
        //   property: `og:image`,
        //   content: seo.image
        // },
        // {
        //   property: `og:image:width`,
        //   content: `${shearImage.childImageSharp.fixed.width}`,
        // },
        // {
        //   property: `og:image:height`,
        //   content: `${shearImage.childImageSharp.fixed.height}`,
        // },

        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
    >

      {/* TODO: Move to a better place */}
      <link rel="icon" href={favicon}></link>
    </Helmet>
  )
}
