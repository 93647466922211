import styled from "styled-components"
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { livePulse } from "../../ConferenceRoom/utils/animations"
import { mediaMin, mediaMax } from "../../../utils/breakpoints"
import Button from "./Button"

interface GoLiveButtonProps {
  className?: string
  onClick?: () => void
  isActive?: boolean
}

export default ({ className, isActive, onClick }: GoLiveButtonProps) => {
  return (
    <GoLiveButton
      cover
      to="/beint"
      bg="#FFFFFF"
      className={isActive ? "active" : ""}
    >
      <div className="live">
        <div className="live-inner"></div>
      </div>
      <div className="text">Í BEINNI</div>
    </GoLiveButton>
  )
}

const GoLiveButton = styled(AniLink)`
  /* TODO: this is Copy pase */
  display: block;
  min-width: 0;
  padding: 0;
  border: 0;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  width: 45px;
  height: 45px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  overflow: hidden;

  &:focus {
    outline: 0;
  }

  width: auto;
  text-decoration: none;
  border-radius: 22.5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .text {
    /* height: 19px;
		width: 107px; */
    color: #303030;
    font-family: ${({theme}) => theme.fonts.fontFamilySecondary};
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 13px;
    text-align: center;
    padding: 0px 18.5px 0 9px;
    height: 14px;
  }

  .live {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background: rgba(255, 82, 82, 1);
      border-radius: 50%;
      animation: ${livePulse} 2s infinite;
    }
  }

  &.active {
    background-color: #f03434;
    .text {
      color: white;
    }

    .live:before {
      background: white;
    }
  }

  @media ${mediaMax.tablet} {
    .live {
      width: 45px;
    }

    .text {
      display: none;
    }
  }
`
